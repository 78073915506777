$primary:    #8c0019;
$primary_light:    #9f0022;
$secoundary: #080808;

$text-color: #080808;

$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;