// 
// layout: footer
//

.footer{
    position: relative;
    border-top: 1px solid #d9d9d9;
    .footer-wrapper{
        position: relative;
        z-index: 2;
        padding: 30px 0 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .footer-over-wrapper {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px solid #d9d9d9;
    }


    .footer-first {
        display: flex;
        .footer-brand {
            margin-right: 30px;

            img {

            }
        }

        .footer-copyright {
            border-top: 1px solid $primary;

            p {
                margin-bottom: 0;
                font-weight: $font-weight-bold;
                font-size: 14px;
                padding-bottom: 3px;
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 3px;
                border-bottom: 1px solid $primary;
            }

            p, a {
                color: $primary;
            }

            a {
                &:hover {
                    color: $black;
                }
            }
        }
    }

    .footer-data{
        text-align: right;
        ul {
            margin-top: 5px;
            margin-bottom: 0;
            display: flex;
            place-content: flex-end;
            li {
                &:last-of-type a {
                    padding-right: 0;
                    border-right: none;
                }
                a {
                    color: rgba($black, .6);
                    border-right: 1px solid $gray-200;
                    padding-left: 25px;
                    padding-right: 25px;
                    display: block;
                }
            }
        }
        p{
            color: $black;
        }
        p{
            margin-left: 20px;
            margin-bottom: 0;
        }
        a{
            &:hover{
                color: $primary;
            }
        }
    }
}


@include media-breakpoint-down(xl) {
    .footer{
        .footer-wrapper {
            display: block;
            .footer-first{
                justify-content: space-between;
            }
        }
    
        .footer-data {
            margin-top: 30px;
            ul {
                place-content: center;
                margin-top: 15px;
                li:last-of-type a {
                    padding-right: 25px;
                }
            }
        }
        .footer-brand, .footer-wrapper, .footer-data {
            text-align: center;
        }
    }
}

@include media-breakpoint-down(lg) {
}


@include media-breakpoint-down(md) {
    .footer {
        .footer-over-wrapper {
            justify-content: center;
        }
        .footer-wrapper {
            .footer-first {
                display: block;
                .footer-brand {
                    margin-right: 0;
                    margin-bottom: 30px;
                }

            }
        }
    }
}


@include media-breakpoint-down(xs) {
    .footer {
        .footer-data {
            ul {
                display: block;
                li, li:last-of-type {
                    a {
                        border-right: none;
                        padding-right: 10px;
                        padding-left: 10px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
