// 
// Buttons
// 
.btn:hover,
.btn:focus,
.btn:active {
    box-shadow: none !important;
}

button {
    cursor: pointer;
}

.buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin-left: -5px;
    margin-right: -5px;
    .btn {
        flex: 1;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.btn {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 40px;
    border: 1px solid $gray-300;
    outline: 0;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: $white;
    color: $text-color;
    border-radius: 0px;
    text-align: center;
    overflow: hidden;
    z-index: 20;

    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
        outline: none;
        background-color: $primary;
        color: $white;
        border-color: $primary;
    }
    &.btn-small {
        padding: 12px 25px;
    }
}

.btn-primary {
    border: 1px solid $primary;
    background-color: $primary;
    color: $white;
    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
        background-color: $white;
        color: $text-color;
        border-color: $gray-300;
    }
}


.link-more{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .line{
        width: 180px;
        max-width: 70%;
        height: 1px;
        background-color: rgba($white, 0.2);
        position: relative;
        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: $secoundary;
            transition: all 0.6s;
        }
    }
    .text{
        margin-left: 20px;
        font-size: 14px;

    }
}

.link-more:hover{
    .line:before{
        width: 100%;
    }
}

@include media-breakpoint-down(xl) {
}

@include media-breakpoint-between(lg, xl) {
    .buttons-wrapper {
        display: block;
        margin-right: 0;
        margin-left: 0;
        .btn {
            margin-right: 0;
            margin-left: 0;

            width: 100%;
            margin-bottom: 10px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .buttons-wrapper {
        display: block;
        margin-right: 0;
        margin-left: 0;
        .btn {
            margin-right: 0;
            margin-left: 0;

            width: 100%;
            margin-bottom: 10px;
        }
    }
}
