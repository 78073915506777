//
// component: hero
//

.section-hero{
    height: 100vh;
    &.hero-small {
        height: 600px;
    }
    .hero-background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position-y: 0;
        background-repeat: no-repeat;
        &.primary-overlay {
            background-position: center center;
            background-color: $primary;
            background-blend-mode: multiply;
        }
    }
}

.hero-title {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    h1, .h1 {
        font-size: 90px;
        line-height: 100px;
        margin-bottom: 5px;
        color: $white;
        font-weight: 300;
        strong {
            font-weight: 900;
        }
    }
    h2, .h2 {
        color: $white;
        font-size: 36px;
        font-weight: 300;
    }
}


@include media-breakpoint-down(xl) {

    .section-hero.hero-small {
        height: 500px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 80px;
            line-height: 75px;
        }
        h2, .h2 {
            font-size: 30px;
        }
    }
}
@include media-breakpoint-down(lg) {

    .section-hero.hero-small {
        height: 400px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 70px;
            line-height: 65px;
        }
        h2, .h2 {
            font-size: 32px;
        }
    }
}

@include media-breakpoint-down(md) {
    .section-hero .hero-background {
        background-position: center center;
    }
    .section-hero.hero-small {
        height: 300px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 60px;
            line-height: 55px;
        }
        h2, .h2 {
            font-size: 25px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .section-hero {
        height: 50vh;
        .hero-background {
            background-position: right center;
        }
        .scroll-down {
            display: none;
        }
    }
    .section-hero.hero-small {
        height: 200px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 35px;
            line-height: 35px;
        }
        h2, .h2 {
            font-size: 22px;
        }
    }
}