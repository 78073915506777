// 
// Modal
// 

.modal-content{
    background-color: $white;
    border: none;
}

.modal-content, .modal-footer, .modal-content{
    border-radius: 0px;
}

.modal-header, .modal-footer{
    border:0px;
}

button.close{
    background-color: $black;
    color: $white;
    text-shadow: none;
    font-size: 15px;
    padding: 15px;
    opacity: .8;
    transition: all .4s;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: 1;
    color: $white;
}

#modalContact {
    .modal-body{
        padding: 35px 50px;
        padding-top: 0;
    }
}

#modalDystrybutorzy {
    h2 {
        font-size: 36px;
        font-weight: $font-weight-medium;
        color: $primary;
        margin-bottom: 20px;
        padding-left: 20px;
    }
    .modal-body{
        padding: 0;
        .g-map {
            padding-bottom: 50%;
        }
    }
}
#modalprivacy {
    h2 {
        font-size: 36px;
        font-weight: $font-weight-medium;
        color: $primary;
        margin-bottom: 20px;
    }
}

.modalFiles {
    h2 {
        font-size: 36px;
        font-weight: $font-weight-medium;
        color: $primary;
        margin-bottom: 20px;
    }
    .modal-body{
        ul {
            li {
                padding-left: 16px;
                position: relative;
                font-size: 17px;
                color: $text-color;
                a {
                    color: $text-color;
                    &:hover {
                        color: $primary;
                    }
                }
                &:before {
                    content: '';
                    top: 9px;
                    left: 0;
                    position: absolute;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    background-color: $primary;
                }
            }
        }
    }
}

.skladOpalu {
    @include media-breakpoint-up(xxl) {
        .modal-dialog {
            max-width: 1430px;
        }
    }
    h2 {
        font-size: 36px;
        font-weight: $font-weight-medium;
        color: $primary;
        margin-bottom: 20px;
        padding-left: 20px;
    }
    h3 {
        font-size: 20px;
        font-weight: $font-weight-medium;
        color: $primary;
        margin-bottom: 20px;
    }
    .modal-body {
        padding: 0;
    }
    .col-contact {
        height: 100%;
        background-color: $gray-100;
        padding-left: 20px;
        padding-top: 30px;
        padding-right: 20px;
        padding-bottom: 30px;
    }
    .col-content {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 40px;
    }
    .sklad-deliveries {
        margin-bottom: 20px;
        h5 {
            font-size: 16px;
            font-weight: $font-weight-bold;
        }
        .sklad-delivery {
            &:not(:last-of-type) {
                border-bottom: 1px solid $gray-300;
            }
            display: flex;
            justify-content: space-between;
            padding: 7px 0;
            p, span {
                font-size: 15px;
                font-weight: $font-weight-light;
            }
            p {
                margin-bottom: 0;
            }
            p, a {
                color: $text-color;
            }
            a:hover {
                color: $primary;
            }
        }
    }
    .media {
        margin-bottom: 20px;
        .media-image {
            width: 150px;
        }
        .media-body {
            margin-left: 20px;
            align-self: center;
            h6 {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 0;
            }
            p, a {
                color: $text-color;
            }
            a:hover {
                color: $primary;
            }
        }
    }
}


@include media-breakpoint-down(lg) {

    .skladOpalu, #modalDystrybutorzy, .modalFiles, #modalprivacy {
        h2 {
            font-size: 22px;
            margin-bottom: 20px;
            padding-left: 20px;
        }
    }
    .modalFiles {
        ul {
            li {
                padding-left: 16px;
                font-size: 14px;

                &:before {
                    top: 7px;
                }
            }
        }
    }
}
@include media-breakpoint-down(xs) {
    .skladOpalu {
        .col-contact, .col-content {
            padding-left: 5px;
            padding-right: 5px;
        }

        table.table-sklad thead tr td {
            font-size: 10px;
        }
    }
}
