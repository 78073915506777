
body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    color: $text-color;
}

ul, ol{
    @extend .list-unstyled;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {

    color: $text-color;
}

h1, .h1 {
    font-size: 90px;
}

h2, .h2 {
    font-size: 72px;
    font-weight: 300;
}

h3, .h3 {
    font-size: 32px;
}

h4, .h4 {
    font-size: 22px;
}

h5, .h5 {
    font-size: 20px;
}

h6, .h6 {
    font-size: 18px;
}


p, ul, ol{
    font-size: 14px;
}
.lead{
    font-size: 18px;
}

a,
a:hover,
a:focus,
button,
button:focus,
button:hover {
    text-decoration: none;
    outline: none;
}

a {
    transition: all 0.35s;
    color: $white;
    &:hover, &:focus{
        color: $secoundary;
    }
}

.text-primary{
    color: $primary !important;
}

.text-white{
    color: $white;
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p{
        color: inherit;
    }
}

@include media-breakpoint-down(xl) {
    h1, .h1 {
        font-size:72px;
    }
    
    h2, .h2 {
        font-size: 58px;
    }
}
@include media-breakpoint-down(lg) {
    h2, .h2 {
        font-size: 48px;
    }
}
@include media-breakpoint-down(md) {}
@include media-breakpoint-down(sm) {
    h2, .h2 {
        font-size: 36px;
    }
}
