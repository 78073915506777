// 
// page: contact
//

.section-form {
  form {
    margin-top: 30px;
  }
  h2 {
    font-size: 36px;
    font-weight: $font-weight-medium;
    color: $primary;
    margin-bottom: 30px;
  }
}

.section-contact {
  padding-top: 50px;
  color: $text-color;

  h3, .h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p {
    color: $text-color;
    margin-bottom: 55px;
  }
}

.contact-title {
  span {
    background-color: $primary;
    display: inline-block;
    color: $white;
    padding: 10px 30px;
  }
  border-bottom: 1px solid $gray-200;
}

ul.contact-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 11px;
  margin-bottom: 11px;
  li {
    padding-top: 11px;
    padding-bottom: 11px;
    .media-image {
      flex: 0 0 150px;
    }
    .media-body {
      margin-left: 30px;
      align-self: center;
      h6 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 0;
      }
      p, a {
        color: $text-color;
      }
      a:hover {
        color: $primary;
      }
    }
  }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {

}

@include media-breakpoint-down(md) {
  /*.section-contact {
    ul.contact-list {
      li {
        flex: 0 0 100%;
      }
    }
  }*/
}

@include media-breakpoint-down(sm) {

  .section-contact {
    ul.contact-list {
      li {
        text-align: center;
      }
    }
    .media {
      display: block;
      .media-image {
        width: 220px;
        display: inline-block;
      }
      .media-body {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }

}

