// 
// layout: footer
//
body.page-scroll {
    .header {
        background-color: $primary;
        .header-row {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .header-brand img {
            max-width: 200px;
        }
    }
}

.header{
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    background-color: transparent;
    transition: all 0.3s;
    
    .header-row{
        display: flex;
        justify-content: space-between;
        padding: 50px 85px;
        transition: all .4s;
    }
    .header-brand{
        img{
            transition: all .4s;
            max-width: 285px;
            display: block;
        }
    }

    ul.header-menu{
        display: flex;
        margin: 0;
        padding: 0;
        li a{
            color: $white;
            font-size: 16px;
            font-weight: bold;
            display: block;
            padding: 16px 22px;
            position: relative;
            text-transform: uppercase;
            &:hover{
                color: rgba($white, .7);
            }
        }
        li a.active{
            color: rgba($white, .7);
        }
    }


    .header-right {
        display: flex;
    }

    .header-lang {
        border-left: 1px solid rgba($white, .6);
        display: flex;
        align-items: center;
        margin-left: 20px;
    }
    .dropdown-link {
        background-color: $primary;
        color: $white !important;
        border-color: $primary;
        text-transform: uppercase;
        border-radius: 5px;
        padding: 8px 12px;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        min-width: 55px;
        position: relative;
        outline: none!important;
        display: inline-block;
        .arrow-round {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(50%, -50%);
            background-color: $blue;
            box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.45);
            [class^="icon"] {
                color: $white;
                transition: 0s;
            }
        }
    }

    .dropdown-link-container {
        .dropdown-menu {
            min-width: 100%;
            background-color: $primary;
            padding: 0;
            border-radius: 5px;
        }
        .dropdown-item {
            padding: 8px 4px;
            padding-left: 15px;
            //text-align: center;
            color: $white;
            border-radius: 5px;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
            background-color: darken($primary, 2.0);
            color: $white;
        }
    }

    .dropdown-link-container.show {
        .arrow-round [class^="icon"] {
            transform: rotate(180deg);
        }
    }


    .dropdown-style2{

        cursor: pointer;
        .dropdown-link{
            background-color: transparent;
            box-shadow: none;
            padding: 0 15px;
            padding-right: 0;
            min-width: 45px;
            margin-left: 10px;
        }

        .icon-arrow-down{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size:6px;
        }

        .dropdown-item{
            border-radius: 0px;
        }

        .dropdown-menu{
            border-radius: 0px;
            border:0px;
            margin-top: 0;
        }
    }

    .header-toggler{
        display: none;
    }
}

@include media-breakpoint-down(xl) {

    .header{
        .header-row{
            padding: 30px 10px;
        }
        ul.header-menu li a {
            padding-left: 10px;
            padding-right: 10px;
        }

        .header-brand {
            img {
                max-width: 150px;
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    .header{
        .header-row{
            padding: 25px 30px;
            align-items: center;
        }

        .header-brand{
            img{
                max-width: 120px;
            }
        }
    
        ul.header-menu, .header-phone, ul.header-lang, .header-phone .phone-gradient {
            display: none;
        }
    
        .header-toggler {
            margin-left: 30px;
            display: flex;
            align-items: center;
        }
    }
    body.page-scroll {
        .header .header-brand {
            img {
                max-width: 150px;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .header {
        .header-row{
            padding: 10px 15px;
        }
        .header-right > img {
            max-width: 150px;
        }
    
        .header-toggler {
            margin-left: 20px;
        }
    }
}

@media (max-width: 475px) {
    .header {
        .header-right > img {
            max-width: 120px;
        }
        .header-lang {
            margin-left: 10px;
        }
    
        .header-toggler {
            margin-left: 10px;
        }
        .dropdown-style2 .dropdown-link {
            margin-left: 0;
        }
    }
}

