//
// FORM CONTROL
//
.form-control {
    border-radius: 0;
    font-size: 13px;
    padding: 20px;
    padding-top: 40px;
    font-weight: 300;
    color: $text-color;
    background-color: transparent;
    border: 1px solid #b2b2b2;
}

.form-control:focus {
    background-color: transparent;
    box-shadow: none;
    border-color: $secoundary;
}

.form-control:disabled, .form-control[readonly]{
    background-color: $black;
}

select.form-control {
    height: auto;
    padding-top: 30px;
    padding-left: 15px;
}


// select
.custom-select{
    height: 50px;
    border-radius: 0px;
    font-size: 13px;
    border-color: $gray-300;
    font-weight: 300;
    color: $dark;
    background: url(../img/svg/down.svg) no-repeat right 0.75rem center/8px 10px;
}

.custom-select:focus{
    border-color: $secoundary;
    box-shadow: none;
}

//
// TEXTAREA
//
textarea.form-control {
    height: auto;
    line-height: 1.3;
}

//
// LABEL
//
label:not(.custom-control-label){
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    color: $text-color;
    position: absolute;
    top: 15px;
    left: 20px;
}


//
// FORM GROUP
//
.form-group {
    position: relative;
    margin-bottom: 15px;
}


// 
// FORM ROW
// 
.form-row {
    margin-right: -20px;
    margin-left: -20px;
}

.form-row > .col, .form-row > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

//
// PLACEHOLDERS
//
input ::-webkit-input-placeholder {
    color: rgba($black, 0.6);
}

input ::-moz-placeholder {
    color:  rgba($black, 0.6);
}

input :-ms-input-placeholder {
    color:  rgba($black, 0.6);
}

input :-moz-placeholder {
    color:  rgba($black, 0.6);
}


//
// CUSTOM RADIO, CUSTOM CHECKBOXES
//
.custom-control{
    label{
        font-size: 12px;
    }
}
.custom-checkbox{
    padding-left: 45px;
    padding-top: 10px;
    a{
        color: $secoundary;
    }
}
.custom-control-label::before{
    content: none;
}
.custom-control-label{
    color: rgba( $white, 0.6);
}
.custom-control-label::after{
    width: 29px;
    height: 29px;
    left: -45px;
    top: 0px;
    background-image: url(../img/checkbox.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
    background-image: url(../img/checkbox.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
}

.has-danger {
    .custom-control-label::after{
        background-position: bottom center;
    }
}

// 
// bootstap select
// 
.bootstrap-select{
    width: 100%!important;
    > .dropdown-toggle{
        border: 1px solid $black;
        background-color: $black;
    }

    >.dropdown-toggle[aria-expanded="true"]{
        border: 1px solid $primary;
        background-color: $white;
    }

    .dropdown-toggle.bs-placeholder {
        color: $white;
    }
    
    > .dropdown-toggle.bs-placeholder:hover,  
    > .dropdown-toggle.bs-placeholder:focus,
    > .dropdown-toggle.bs-placeholder:active {
        color: $white;
    }
    
    .dropdown-toggle[aria-expanded="true"]{
        border: 1px solid $black;
        background-color: $black;
    }

    >.dropdown-toggle:active, >.dropdown-toggle.active{
        background-color: $black!important;
        border: 1px solid $black;
        
    }
    >.dropdown-toggle:focus{
        outline: none!important;
        box-shadow: none!important;
        border: 1px solid $primary;
    }

    .dropdown-item.active, .dropdown-item:active{
        background-color: $primary;
    }

    .dropdown-menu, .dropdown-item:last-child, .dropdown-item:first-child{
        border-radius: 0px;
    }

    .dropdown-toggle::after{
        border:0px;
        content: "\e908";
        font-family: 'icomoon' !important;
        color: $white;
        font-size: 7px;
    }
}

.bootstrap-select .dropdown-menu{
    border-radius: 0px;
    padding: 0;
    background-color: $black;
    .dropdown-item{
        padding: 8px 20px;
        font-size: 13px;
        font-weight: 500;
        color: $white;
    }
    .dropdown-item:hover, .dropdown-item:focus {
        color: $white;
        text-decoration: none;
        background-color: $secoundary;
    }
    .dropdown-item.active, .dropdown-item:active{
        background-color: $secoundary;
    }
}

.dropdown-toggle.btn:before{
    content: none;
}


//
// VALIDATION
//
.parslay-danger {
    display: none;
}

.form-control.form-control-danger {
    border-color: $danger;
}

.form-group.has-danger {
    label:before {
        border-color: $danger;
    }
}
.bootstrap-select > .dropdown-toggle.form-control-danger{
    border-color: $danger;
}

//
// [data-filter]
// 
.data-filter-group div[data-filter] {
    display: none;
}

.data-filter-group div[data-filter].active {
    display: block;
}

.has-danger{
    .input-group{
        border-color: red;
    }
}



@include media-breakpoint-down(sm) {
    .form-row {
        margin-right: 0;
        margin-left: 0;
    }

    .form-row > .col, .form-row > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    .btn[type=submit] {
        display:block;
        width: 100%;
    }
}
