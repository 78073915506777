//
// Bredcrumbs
//
.breadcrumb {
    position: relative;
    z-index: 4;
    background-color: transparent;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    padding-left: 12px;
    
    &-item,
    &-item a {
        font-family: inherit;
        font-size: 13px;
        color: $white;
        transition: all 0.3s;
        font-weight: 400;
    }
    &-item a:hover {
        color: $secoundary;
    }
    &-item+&-item{
        padding: 0;
    }
    &-item+&-item::before {
        padding-right: 6px;
        padding-left: 6px;
        content: "-";
        color: $white;
    }
    &-item.active {
        color: $secoundary;
    }
}

@include media-breakpoint-down(md) {
    .breadcrumb{
        display: none;
    }
}

