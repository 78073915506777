// 
// page: home
// 
.scroll-down {
  position: absolute;
  bottom: 50px;
  color: $white;
  font-size: 35px;
  right: 50%;
  transform: translateX(-50%);
  animation: anim-bounce-sm 0.85s ease-in-out infinite alternate;
  &:hover, &:focus {
    color: $primary;
  }
}

@keyframes anim-bounce-sm {
  from {
    bottom: 50px; }
  to {
    bottom: 57px; }
}

.box-content {
  color: $text-color;

  h2 {
    font-size: 75px;
    font-weight: 900;
    color: $primary;
    line-height: 45px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 55px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  p {
    font-size: 17px;
  }
  a:not(.btn) {
    font-size: 17px;
    color: $primary;
    &:hover, &:focus {
      color: $black;
    }
  }
  ul {
    li {
      padding-left: 16px;
      position: relative;
      font-size: 17px;
      &:before {
        content: '';
        top: 9px;
        left: 0;
        position: absolute;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: $primary;
      }
    }
  }

  .buttons-wrapper {
    margin-top: 40px;
  }
}

.box-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;
  overflow: hidden;
  position: relative;

  &.box-left {
    .box-image {
      left: 0;
      right: auto;
    }
  }

  .box-image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
  }

  &.home-about {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 60px;
    padding-bottom: 60px;

    .box-image {
      .bg-image {
        position: absolute;
        background-color: $primary;
        background-blend-mode: darken;
        background-size: cover;
        background-position: center center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      &:before {
        content: '';
        width: 100px;
        height: 100%;
        background-color: $primary_light;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      .product-image {
        position: absolute;
        left: -120px;
        top: 60px;
        z-index: 10;


        img {
          position: relative;
          z-index: 2;
        }
      }
    }
  }
}
.home-default {
  padding-top: 50px;
  padding-bottom: 50px;
  .box-content {
    padding-top: 80px;
  }
  .box-image {
    .swiper-gallery {
      position: relative;
      height: 100%;
      padding-left: 25px;
      padding-right: 25px;
      &:before {
        content: '';
        background-color: $primary;
        position: absolute;
        left: 15%;
        width: 65%;
        top: 10%;
        bottom: 10%;
        z-index: 0;
      }
      .swiper-navigation {
        .swiper-button-prev, .swiper-button-next {
          background-color: $white;
          border: 1px solid $black;
          padding: 10px;
          width: 60px;
          height: 60px;
          outline: 0;
          box-shadow: none;
          transition: all .4s;
          background-image: none;
          &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 13px;
            font-family: 'icomoon' !important;
            content: "\e935";
            transform: translate(-50%, -50%);
          }
          &:hover {
            border-color: $primary;
            background-color: $primary;
            &:before {
              color: $white;
            }
          }
        }
        .swiper-button-prev {
          top: 27%;
          left: 0;
          &:before {
            transform: translate(-50%, -50%) rotate(-180deg);
          }
        }
        .swiper-button-next {
          top: auto;
          right: 0;
          bottom: 27%;
        }
      }
    }
    .swiper-wrapper {
      height: calc(665px + 150px);
      .swiper-slide {
        &.swiper-slide-active, &.swiper-slide-next {
          position: relative;
          height: 100%;
          .about-image {
            width: map-get($grid-breakpoints, xl) * .39 !important;
          }
        }

        &.swiper-slide-active {
          .about-image {
            top: 0;
            right: auto;
            left: 0;
          }
        }

        &.swiper-slide-next, &.swiper-slide-prev {
          .about-image {
            right: 0;
            top: 150px;
          }
        }
        .about-image {
          top: 0;
          width: 100%;
          position: absolute;
          height: 665px;
          background-size: cover;
          background-repeat: no-repeat;
          transition: top .4s;
        }
      }
    }
    /*.about-image, .about-image2 {
      width: 65%;
      padding-bottom: 94%;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .about-image {
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .about-image2 {
      position: absolute;
      right: 0;
      top: 30%;
      z-index: 2;
    }*/
  }

}

table.table-sklad {
  width: 100%;
  border: 2px solid #e5e5e5;
  margin-bottom: 20px;
  td {
    padding: 7px;
    text-align: center;
  }
  thead {
    tr td {
      background-color: #000;
      color: $white;
      font-weight: $font-weight-bold;
      border-right: 1px solid #4d4d4d;
      text-transform: uppercase;
    }
  }
  tbody {
    tr {
      td {
        &:first-of-type {
          border-bottom: 1px solid #7d0216;
          background-color: $primary;
          color: $white;
          text-transform: uppercase;
        }
        &:last-of-type, &:nth-child(4) {
          color: $primary;
          font-weight: bold;
        }
        border-bottom: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
      }
    }
  }
}
table.default-table {
  margin-top: 70px;
  width: 100%;

  tr {
    &:not(:last-of-type) {
      border-bottom: 1px solid $gray-300;
    }

    td {
      padding: 10px 0;
      width: calc(100% - 130px);

      &:last-of-type {
        width: 130px;
        padding-right: 20px;
        padding-left: 20px;
        background-color: $gray-200;
        font-weight: bold;
        text-align: right;
      }
    }
  }
}

@include media-breakpoint-down(xxl) {

  .box-body.home-about {
    .box-image {
      .product-image {
        left: -60px;
        max-width: 500px;
      }
    }
  }
}
@include media-breakpoint-down(xl) {

  .box-body.home-about {
    .box-image {
      .product-image {
        left: -60px;
        top: 50%;
        transform: translateY(-50%);
        max-width: 400px;
      }
    }
  }
  .home-default .box-image .swiper-wrapper .swiper-slide.swiper-slide-active .about-image, .home-default .box-image .swiper-wrapper .swiper-slide.swiper-slide-next .about-image {
    width: map-get($grid-breakpoints, xl) * .30 !important;
  }
}

@include media-breakpoint-down(lg) {
  .home-default .box-content {
    padding-top: 0;
  }
  .box-body.home-about {
    display: block;
    padding: 20px 0;
    .box-image {
      position: relative;
      width: 100%;
      .bg-image {
        display: none;
      }
      &:before {

        display: none;
        //left: 50%;
        //transform: translateX(-50%);
      }
      .product-image {
        position: relative;
        max-width: 300px;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        padding: 30px 0;
      }
    }
  }
  .box-content {

    h2 {
      font-size: 65px;
      line-height: 60px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 40px;
    }

    p {
      font-size: 14px;
    }
    ul {
      li {
        padding-left: 16px;
        font-size: 14px;
        &:before {
          top: 7px;
        }
      }
    }

    .buttons-wrapper {
      margin-top: 40px;
      margin-bottom: 50px;
    }
  }

  .home-default .box-image .swiper-wrapper .swiper-slide.swiper-slide-active .about-image, .home-default .box-image .swiper-wrapper .swiper-slide.swiper-slide-next .about-image {
    width: map-get($grid-breakpoints, xl) * .25 !important;
  }

}

@include media-breakpoint-down(md) {
  .box-content {

    h2 {
      font-size: 60px;
      line-height: 55px;
    }

    h3 {
      font-size: 32px;
    }
  }
  .home-default .box-image .swiper-wrapper .swiper-slide.swiper-slide-active .about-image, .home-default .box-image .swiper-wrapper .swiper-slide.swiper-slide-next .about-image {
    width: map-get($grid-breakpoints, lg) * .5 !important;
  }

}

@include media-breakpoint-down(sm) {
  .box-content {

    h2 {
      font-size: 40px;
      line-height: 35px;
    }
  }
  .home-default .box-image .swiper-wrapper .swiper-slide.swiper-slide-active .about-image, .home-default .box-image .swiper-wrapper .swiper-slide.swiper-slide-next .about-image {
    width: map-get($grid-breakpoints, md) * .45 !important;
  }

}
@include media-breakpoint-down(sm) {
  .box-content {

    h2 {
      font-size: 40px;
      line-height: 35px;
    }
  }
}
@include media-breakpoint-down(xs) {
  .home-default {
    .box-image {
      .swiper-gallery {
        //padding-right: 0;
        //padding-left: 0;
        .swiper-navigation {
          .swiper-button-prev {
            top: 50%;
          }

          .swiper-button-next {
            top: 50%;
            bottom: auto;
          }
        }
      }
      .swiper-wrapper {

        height: 400px;

        .swiper-slide {
          &.swiper-slide-active, &.swiper-slide-next {
            position: relative;
            height: 100%;

            .about-image {
              width: 100% !important;
            }
          }
          .about-image {
            height: 400px;
          }
          &.swiper-slide-active {
            .about-image {
              top: 0;
              right: auto;
              left: 0;
            }
          }

          &.swiper-slide-next, &.swiper-slide-prev {
            .about-image {
              right: 0;
              top: 0;
            }
          }
        }
      }
    }
  }

}


