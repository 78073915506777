//
// Cookie
//
.cookie {
    position: fixed;
    z-index: 9999;
    left: 0;
    right: 0;
    bottom: -100%;
    padding: 19px 20px;
    background-color: $dark;
    transition: all 0.5s;
    overflow: hidden;
    opacity: 0;
    &-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &-text {
        color: $white;
        margin: 0;
    }
    &.show {
        bottom: 0%;
        opacity: 1;
    }
}

@include media-breakpoint-down(xl) {
    .cookie{
        padding: 25px 0px;
        .btn{
            padding: 8px 16px;
            font-size: 12px;
        }
        p{
            font-size: 13px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .cookie{
        padding: 10px;
        .btn{
            margin-top: 15px;
        }
    }
}